import moment from 'moment';
import { PATH_MAP } from './constants';

export function getTitle(path) {
    return PATH_MAP[path].title;
}

export function getIcon(path) {
    return PATH_MAP[path].icon;
}

export function getPathInfo(path) {
    return PATH_MAP[path];
}

export function getBrowserTitle(path) {
    const info = getPathInfo(path);
    return `${info.title} - Boterath 🤖`;
}

export function extractUsername(location) {
    const path = location.pathname;
    const username = path.split('/')[1];
    // If the path is /login, we don't want to fetch data
    if (username === 'login') {
        return null;
    }
    return username;
};

export function extractPath(location) {
    const path = location.pathname.split('/');
    if (path.length > 2) {
        return path.slice(2).join('/');
    }
    return '';
};

export function getDuration(duration) {
    const seconds = duration % 60;
    const minutes = ((duration - seconds) / 60) % 60;
    const hours = (duration - minutes * 60 - seconds) / 3600;
    const days = (duration - hours * 3600 - minutes * 60 - seconds) / 86400;
    return `${days ? days + 'd' : ''} ${hours ? hours + 'h' : ''} ${minutes ? minutes + 'm' : ''} ${seconds ? seconds + 'm' : ''}`;
}

export function formatTime(time) {
    return moment(time).format("YYYY-MM-DD HH:mm:ss");
}
