export const PATH_MAP = {
    '': { title: 'Dashboard', icon: '📊' },
    'chatters': { title: 'Chatters', icon: '🧑‍🤝‍🧑' },
    'chat': { title: 'Chat log', icon: '🧾' },
    'commands': { title: 'Commands', icon: '🪄' }
};

export const API_MAP = {
    channels: "channels",
    chatters: "chatters",
    chat: "chat",
    commands: "commands",
    channel: "channel",
    user: "user",
    session: "session"
};

export const POST_API_MAP = {
    addCommand: "command/add",
    editCommand: "command/edit",
    deleteCommand: "command/delete",
    login: "session/login",
    logout: "session/logout",
    validateSession: "session/validate"
}
