import { API_MAP, POST_API_MAP } from './constants';

export const getApiBase = () => process.env.REACT_APP_API_BASE;

async function redirectToLogin(errorMessage = null) {
    if (window.location.pathname === '/login') {
        console.log('Already on /login, skipping redirect.');
        return null;
    }

    window.location.href = `/login?redirect=${window.location.href}`;
    return null;
};

export async function fetchData(apiName, attribute, callBack, args) {
    let url = `${getApiBase()}/${API_MAP[apiName]}`;

    if (args && args.username) {
        url += `/${args.username}`;
    }

    return fetch(url).then(
        response => {
            return response.json().then(
                data => {
                    if (response.status !== 200) {
                        let errorMessage = response.status;
                        if (data[attribute] && data[attribute].message) {
                            errorMessage = `${errorMessage}: ${data[attribute].message}`;
                        }
                        throw new Error(response.status);
                    }
                    return data;
                }
            )
        }
    ).then(
        data => data[attribute]
    ).then(
        data => callBack(data)
    ).catch(
        error => {
            // statusCode is an Error object
            if (error.message.startsWith('401')) {
                console.log("Redirecting to login");
                return redirectToLogin();
            }
            console.log(`Error fetching data: ${error.message} `)
            return [];
        }
    )
}

export async function postData(apiName, attribute, data) {
    let url = `${getApiBase()}/${POST_API_MAP[apiName]}`;

    return fetch(
        url,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }
    ).then(
        response => {
            return response.json().then(
                data => {
                    if (response.status !== 200) {
                        let errorMessage = response.status;
                        if (data[attribute] && data[attribute].message) {
                            errorMessage = `${errorMessage}: ${data[attribute].message}`;
                        }
                        throw new Error(errorMessage);
                    }
                    return data;
                }
            )
        }
    ).then(
        data => data[attribute]
    ).catch(
        error => {
            if (error.message.startsWith('401') && apiName !== 'login') {
                console.log("Redirecting to login");
                return redirectToLogin();
            }

            console.log(`Error fetching data: ${error.message}`)
            return { error: error.message.split(':')[1] };
        }
    )
}
