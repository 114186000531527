import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { postData } from '../api';

const Login = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const redirect = searchParams.get('redirect');
  const [errorMessage, setErrorMessage] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    postData('login', 'session', { username, password, redirect }).then(
      (session) => {
        console.log(session);
        if (session && session.user) {
          window.location.href = session.redirect;
        } else {
          setErrorMessage(session.error);
        }
      }
    );
  };

  return (
    <section id="login">
      <form onSubmit={handleSubmit}>
        <label>
          <input type="text" placeholder="Username" value={username} onChange={handleUsernameChange} />
        </label>
        <label>
          <input type="password" placeholder="Password" value={password} onChange={handlePasswordChange} />
        </label>
        {errorMessage && <div class="error-message">{errorMessage}</div>}
        <button type="submit" style={{ display: 'none' }}></button>
        <a onClick={(event) => handleSubmit(event)} class="login-button">Log In</a>
      </form>
    </section>
  );
};

export default Login;
